// Sidebar.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch, faTags, faUpload, faBolt, faInfo, faBars, faTimes, faCircleInfo, faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";

const Sidebar = ({ user, selectedGroup }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getIcon = (icon, label, fadeIcon = false) => {
    if (isCollapsed) {
      return (
        <div className="collapsed-icon">
          <FontAwesomeIcon icon={icon} beatFade={fadeIcon} />
          <span>{label}</span>
        </div>
      );
    } else {
      return (
        <div className="expanded-icon">
          <FontAwesomeIcon icon={icon} fade={fadeIcon} />
          <span>{label}</span>
        </div>
      );
    }
  };

  const isActiveRoute = routePath => {
    return location.pathname === routePath ? "active" : "";
  };

  const getAvailableFeatures = () => {
    if (!selectedGroup || !user) return [];

    const group = user.attached_policies.find(policy => policy.group_name === selectedGroup);
    if (!group) return [];

    return group.features.map(f => f[1]);
  };

  const features = getAvailableFeatures();

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <button onClick={toggleCollapse} className="toggle-button">
        {isCollapsed ? getIcon(faBars) : getIcon(faTimes)}
      </button>
      <div className={`nav ${isCollapsed ? "collapsed" : ""}`}>
        {/* {features.includes("dashboard") && ( */}
          <Link to="/dashboard" className={`nav-link ${isActiveRoute("/dashboard")}`}>
            {getIcon(faHome, "Home")}
          </Link>
        {/* )} */}
        {(features.includes("search_sharedtopics") || features.includes("search_keywords") || features.includes("search_expertise")) && (
          <Link to="/search" className={`nav-link ${isActiveRoute("/search")}`}>
            {getIcon(faSearch, "Search")}
          </Link>
        )}
        {(features.includes("aiir") || features.includes("aiir_organization") || features.includes("aiir_faculty"))&& (
          <Link to="/aiirsearch" className={`nav-link ${isActiveRoute("/aiirsearch")}`}>
            {getIcon(faBolt, "AIIR", true)}
          </Link>
        )}
        {features.includes("tags") && (
          <Link to="/tags" className={`nav-link ${isActiveRoute("/tags")}`}>
            {getIcon(faTags, "Tags")}
          </Link>
        )}
        {features.includes("upload") && (
          <Link to="/upload" className={`nav-link ${isActiveRoute("/upload")}`}>
            {getIcon(faUpload, "Upload")}
          </Link>
        )}
        {/* {features.includes("about") && ( */}
          <Link to="/about" className={`nav-link ${isActiveRoute("/about")}`}>
            {getIcon(faCircleInfo, "About")}
          </Link>
        {/* )} */}
        {/* {features.includes("people") && ( */}
          <Link to="/people" className={`nav-link ${isActiveRoute("/people")}`}>
            {getIcon(faUsersRectangle, "People")}
          </Link>
        {/* )} */}
      </div>
    </div>
  );
};

export default Sidebar;

