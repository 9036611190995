import React, { useState, useEffect } from "react";
import Table from "../Utilities/Table/Table";
import { getData, postData, deleteData } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEdit,
  faTrash,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Tags.css";
import { API_MODULES } from "../Services/Settings";
import { capitalizeFirstLetter } from "../Utilities/utils";

const Tags = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [editedTag, setEditedTag] = useState({
    tagid: "",
    taglabel: "",
    show: false,
  });
  const [deletedTag, setDeletedTag] = useState({
    tagid: "",
    taglabel: "",
    show: false,
  });
  const [newTagValue, setNewTagValue] = useState("");
  const [facultiesForTags, setFacultiesForTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData(API_MODULES.TAGS, 'getTags');
        setSearchResults(data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    const emails = [];
    facultiesForTags.forEach((faculty) => {
      emails.push(faculty.email);
    });
    setSelectedEmails(emails);
  }, [facultiesForTags]);

  useEffect(() => {
    if(selectedEmails.length < facultiesForTags.length){
      setSelectAllChecked(false);
    }
  }, [selectedEmails]);

  const handleSearch = (e) => {
    setSearchWord(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform search logic with the search term
    setSearchTerm(searchWord);
  };

  const handleEdit = (row) => {
    setEditedTag({
      tagid: row.tagid,
      taglabel: row.taglabel,
      show: true,
    });
  };

  const handleDelete = (row) => {
    setDeletedTag({
      tagid: row.tagid,
      taglabel: row.taglabel,
      show: true,
    });
  };

  const handleClose = () => {
    setEditedTag((prevEditedTag) => ({
      ...prevEditedTag,
      show: false,
    }));
    setDeletedTag((prevEditedTag) => ({
      ...prevEditedTag,
      show: false,
    }));
    setShowEmailForm(false);
    setEmailSubject("");
    setEmailMessage("");
  };

  const handleTagUpdate = async (tagId, NewTag) => {
    const tagAlreadyExists = searchResults.some((tag) => tag.taglabel === NewTag);
    if (tagAlreadyExists) {
      toast.warn("A Tag with the same name already exists!");
      toast.error("Failed to update tag");
      return;
    }

    try {
      const params = { tag_id: tagId, new_taglabel: NewTag };
      const editTagResponse = await postData(API_MODULES.TAGS, 'editTag', params);

      if (!editTagResponse.ok) {
        throw new Error("Failed to edit tag.");
      }

      setSearchResults((prevTags) =>
        prevTags.map((tag) =>
          tag.tagid === tagId ? { ...tag, taglabel: NewTag } : tag
        )
      );

      toast.success("Tag updated successfully!");
      handleClose();
    } catch (error) {
      toast.error("Failed to edit tag!");
      console.error("Error editing tag:", error);
    }
  };

  const handleTagDelete = async (tagId) => {
    try {
      const params = { tag_id: tagId };
      const response = await deleteData(API_MODULES.TAGS, 'deleteTag', params);

      if (response.ok) {
        console.log("Tag deleted successfully");
        setSearchResults((prevTags) =>
          prevTags.filter((tag) => tag.tagid !== tagId)
        );
        toast.success("Tag deleted successfully!");
        handleClose();
      } else {
        toast.error("Failed to delete tag!");
        console.error("Failed to delete tag");
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
      toast.error("Error deleting tag!");
    }
  };

  const handleExport = async (tag) => {
    try {
      let params = { tag_id: tag.tagid };

      const response = await getData(API_MODULES.TAGS, 'getTagCsv', params);
      const data = response.data;

    if (!data || data.length === 0) {
      throw new Error("No data available to export");
    }

    // Define CSV headers
    let exportCsv = [['Name', 'Email', 'Designation', 'Expertise', 'Tags']];

    // Process the data and push each row into exportCsv
    data.forEach((item) => {
      exportCsv.push([
        item.name || '', // Ensure that name is not undefined
        item.email || '',
        item.designation || '',
        `"${item.expertise}"` || '',
        `"${item.tags}"` // Enclose tags in double quotes to keep them in the same cell
      ]);
    });

    // Convert the CSV array to a string
    let csvContent = "data:text/csv;charset=utf-8," + exportCsv.map(e => e.join(",")).join("\n");

    // Create a temporary download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Tag-Export-${tag.taglabel}.csv`);

    // Simulate a click on the download link
    link.click();

    toast.success("CSV file exported successfully!");
      // if (!response.ok) {
      //   throw new Error("Failed to download CSV file");
      // }

      // // Extract the filename from the response headers
      // const contentDisposition = response.headers.get("content-disposition");
      // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      // const matches = filenameRegex.exec(contentDisposition);
      // const filename =
      //   matches && matches[1]
      //     ? matches[1].replace(/['"]/g, "")
      //     : `${tag.text}.csv`;

      // const blob = await response.blob();

      // // Create a temporary download link
      // const url = window.URL.createObjectURL(blob);
      // const link = document.createElement("a");
      // link.href = url;
      // link.download = filename;

      // // Simulate a click on the download link
      // link.click();

      // // Clean up the temporary URL
      // window.URL.revokeObjectURL(url);
      // toast.success("CSV file exported successfully!");
    } catch (error) {
      console.error("Error downloading CSV file:", error);
      // Handle error, show notification, etc.
    }
  };

  const handleSendEmail = async () => {
    console.log("subject: ", emailSubject);
    console.log("message: ", emailMessage);
    if (emailSubject === "") {
      toast.warn("Email subject cannot be empty");
      return;
    }
    if (emailMessage === "") {
      toast.warn("Email message cannot be empty");
      return;
    }
    try {
      for (const email of selectedEmails) {
        console.log("email: ", email);
        const sendEmailResponse = await postData(
          `api/Home/Email/saveAndSendEmail`,
          {
            to: email,
            subject: emailSubject,
            message: emailMessage,
          }
        );
        if (!sendEmailResponse.ok) {
          throw new Error("Failed to send email");
        }
      }
      toast.success("Email sent successfully!");
      handleClose();
    } catch (error) {
      toast.error("Failed to send email!");
      console.error("Error sending email:", error);
    }
  };

  const handleViewButtonClick = async (tag) => {
    setIsLoading(true);
    try {
      const params = { tag_label: tag };
      const data = await getData(API_MODULES.TAGS, 'getFacultiesForTag', params);
      setSelectedTag(tag);
      setFacultiesForTags(data.data);
      setShowData(true);
      setSelectAllChecked(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter(
          (selectedEmail) => selectedEmail !== email
        );
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      // If it's checked, uncheck all checkboxes
      setSelectedEmails([]);
    } else {
      // If it's unchecked, check all checkboxes
      setSelectedEmails(facultiesForTags.map((faculty) => faculty.email));
    }
    // Toggle the select all checkbox
    setSelectAllChecked(!selectAllChecked);
  };

  const tagsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        // Header: "Tags",
        Header: () => (
          <div className="custom-header">
            <span>Tags</span>
            <span className="count-badge">{searchResults.length}</span>
          </div>
        ),
        accessor: "taglabel",
        // Enable sorting for this column
        sortType: "alphanumeric",
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ({ row }) => (
          <div>
            <Tooltip title="Edit tag" placement="bottom-start">
              <FontAwesomeIcon
                icon={faEdit}
                className="edit-icon"
                onClick={() => handleEdit(row.original)}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "delete",
        Cell: ({ row }) => (
          <div>
            <Tooltip title="Delete tag" placement="bottom-start">
              <FontAwesomeIcon
                icon={faTrash}
                className="delete-icon"
                onClick={() => handleDelete(row.original)}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "export",
        Cell: ({ row }) => (
          <div>
            <Tooltip title="Export CSV" placement="bottom-start">
              <FontAwesomeIcon
                icon={faFileExport}
                className="export-icon"
                onClick={() => handleExport(row.original)}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row }) => (
          <div>
            <a
              className="view-button"
              tabIndex="0"
              onClick={() => {
                handleViewButtonClick(row.original.taglabel);
              }}
            >
              View
            </a>
          </div>
        ),
      },
    ],
    [searchResults]
  );

  const facultyColumns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <span>Faculty Name</span>
            <span className="count-badge">{facultiesForTags.length}</span>
          </div>
        ),
        accessor: "name",
        sortType: "alphanumeric",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: () => (
          <div className="custom-header">
            <Tooltip
              title={selectAllChecked ? "Unselect all" : "Select all"}
              placement="bottom-start"
            >
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAllChange}
              />
            </Tooltip>
          </div>
        ),
        accessor: "checkbox",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedEmails.includes(row.original.email)}
            onChange={() => handleCheckboxChange(row.original.email)}
          />
        ),
      },
    ],
    [facultiesForTags, selectedEmails, selectAllChecked]
  );

  return (
    <div className="tags-container">
      <div className="tags-header">
        <div className="tags-title">
          <div className="background-image" />
          <h1 className="section-title">
            <span className="black-on-gold">Tags Lookup</span>
          </h1>
          <p>
            Welcome to "Tags Lookup" – your central hub for tags management.
            Edit, delete, and export tags effortlessly, while exploring
            associated faculties and sending emails directly. Streamline your
            workflow and collaboration seamlessly.
          </p>
        </div>
        {/* Removing the search box to reduce calls to DB */}
        {/* <div className="search-box-container">
          <input
            type="text"
            placeholder="Search for tags"
            className="search-box"
            value={searchWord}
            onChange={handleSearch}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          <button
            type="submit"
            className="search-button"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </button>
        </div> */}
        <div className="table-container">
          {isLoading && <LoadingSpinner />}
          <div className="tags-main-panel">
            {searchResults.length > 0 ? (
              <>
                <Table
                  columns={tagsColumns}
                  data={searchResults}
                  disableFilterOptionsForIds={[
                    "edit",
                    "delete",
                    "action",
                    "export",
                    "s.no",
                  ]}
                  defaultShowFilterForIds={["text"]}
                />
              </>
            ) : (
              !isLoading && (
                <p className="no-data-text">No tags data available</p>
              )
            )}
          </div>
          <div className="tags-side-panel">
            {searchResults.length > 0 ? (
              <div className="side-panel-data-container">
                {showData ? (
                  <div>
                    <div className="side-panel-title">
                      Faculties associated with tag: {selectedTag}
                    </div>
                    <Table
                      columns={facultyColumns}
                      data={facultiesForTags}
                      disableFilterOptionsForIds={["checkbox"]}
                      showPagination={false}
                      tableDataHeight={"372px"}
                    />
                    <div className="email-button-container">
                      <button
                        type="button"
                        className="email-button"
                        onClick={() => setShowEmailForm(true)}
                        disabled={selectedEmails.length === 0}
                      >
                        Send Email
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="no-data-text">
                    Click on View button to view faculties associated with tags
                    and send bulk emails
                  </p>
                )}
              </div>
            ) : (
              !isLoading && (
                <p className="no-data-text">
                  Create tags and view faculties associated with them here
                </p>
              )
            )}
          </div>
        </div>
      </div>
      <Dialog open={editedTag.show} onClose={handleClose}>
        <DialogTitle>Edit Tag</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter new name for the tag <strong>{editedTag.taglabel}</strong> below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            defaultValue={editedTag.taglabel}
            onChange={(e) => setNewTagValue(e.target.value)}
            // label="Email Address"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="Mui-button">
            Cancel
          </Button>
          <Button
            onClick={() => handleTagUpdate(editedTag.tagid, newTagValue)}
            className="Mui-button save"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deletedTag.show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Tag</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-description"
          >
            Are you sure you want to delete the tag{" "}
            <strong>{deletedTag.taglabel}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="Mui-button">
            Cancel
          </Button>
          <Button
            onClick={() => handleTagDelete(deletedTag.tagid)}
            className="Mui-button delete"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEmailForm} onClose={handleClose}>
        <DialogContent>
          <input
            placeholder="Subject"
            type="text"
            className="email-subject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            autoFocus
          />
          <ReactQuill
            theme="snow"
            className="email-message"
            value={emailMessage}
            onChange={setEmailMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="Mui-button">
            Cancel
          </Button>
          <Button onClick={() => handleSendEmail()} className="Mui-button save">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Tags;
