import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faFileExport } from "@fortawesome/free-solid-svg-icons";
import "./AIR.css";
import { Tooltip } from "@mui/material";
import TableWithAccordian from "./TableWithAccordian";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { AIR_API_BASE_URL, NEW_AIIR_API_BASE_URL } from "../Services/Settings";
import { toast } from "react-toastify";
import TableWithAccordian_New from "./TableWithAccordian_New";
import { getDataFromAiirAPI } from "../Services/AccessAPI";
import { capitalizeFirstLetter } from "../Utilities/utils";

const tabFeatureMapping = {
  aiir_organization: "aiir_organization",
  aiir_faculty: "aiir_faculty",
};

const AIIR_New = ({ user, selectedGroup }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("aiir_faculty");
  const [isLoading, setIsLoading] = useState(false);
  const [facultyData, setFacultyData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [cache, setCache] = useState({});
  const [isApiCalled, setIsApiCalled] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const getAvailableTabs = () => {
    if (!user || !selectedGroup) return [];

    const group = user?.attached_policies?.find(policy => policy.group_name === selectedGroup);
    if (!group) return [];

    return group?.features?.map(f => f[1]);
  };

  const availableTabs = getAvailableTabs();

  const fetchSearchResults = async (term, tab) => {
    const cacheKey = `${tab}_${term}`;
    if (cache[cacheKey]) {
      // Use cached results
      const cachedData = cache[cacheKey];
      if (tab === "aiir_faculty") {
        setFacultyData(cachedData || []);
        setOrgData([]);
      } else {
        setOrgData(cachedData || []);
        setFacultyData([]);
      }
      return;
    }

    if (!term) return; // Prevent API call if search term is empty

    setIsLoading(true);
    setIsApiCalled(true);
    const searchParam = tab === "aiir_faculty" ? "faculty" : "organization";

    try {
      // const response = await fetch(
      //   `${NEW_AIR_API_BASE_URL}semantic-search?input_text=${encodeURIComponent(term)}&num_searches=100&type=${searchParam}`
      // );
      // const data = await response.json();

      const params = {
        group_name: localStorage.getItem("selectedGroup"),
        type: tab === "aiir_faculty" ? "faculty" : "org",
        input_text: term
      }

      const data = await getDataFromAiirAPI(params);

      if (tab === "aiir_faculty") {
        // setFacultyData(data.faculty || []);
        setFacultyData(data || []);
        setOrgData([]);
      } else {
        setOrgData(data || []);
        setFacultyData([]);
      }

      // Cache the results
      setCache((prevCache) => ({
        ...prevCache,
        [cacheKey]: data,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      fetchSearchResults(searchTerm, activeTab);
    }
  };

  const handleTabChange = (tab) => {
    const availableTabs = getAvailableTabs();
    if (availableTabs.includes(tabFeatureMapping[tab])) {
      setActiveTab(tab);
      if (searchTerm) {
        fetchSearchResults(searchTerm, tab);
      }
    }
  };

  const facultyColumns = React.useMemo(
    () => [
      {
        Header: "Faculty Name",
        accessor: (value) => value["Name"],
        filter: (rows, id, filterValue) => {
          return rows.filter(row => {
              const name = row.original.Name.toLowerCase();
              const email = row.original.Email.toLowerCase();
              return name.includes(filterValue.toLowerCase()) || email.includes(filterValue.toLowerCase());
          });
        },
        Cell: ({ row }) => {
            // Extract domain from email with error handling
            let domain = "";
            if (row.original.Email && row.original.Email.includes("@")) {
              const parts = row.original.Email.split("@");
              if (parts[1] && parts[1].includes(".")) {
                // Split the domain part on dots
                const domainParts = parts[1].split(".");
                if (domainParts.length > 2) {
                  // Handle cases like "biology.utah.edu"
                  domain = domainParts[domainParts.length - 2]; // Get the second last part
                } else {
                  domain = domainParts[0]; // Normal case
                }
              }
            }
        
            return (
                <span className="aiir-accordian-title">
                    <a href={row.original.URL} target="_blank" rel="noopener noreferrer">
                        {capitalizeFirstLetter(row.original.Name)} 
                        {/* {domain && (
                            <strong className={`university-text ${domain.toLowerCase()}`} style={{marginLeft: 8}}> {domain?.toUpperCase()}</strong>
                        )} */}
                    </a>
                    
                    <span>
                        {domain && (
                            <strong className={`university-text ${domain.toLowerCase()}`} style={{marginRight: 8}}> {domain?.toUpperCase()}</strong>
                        )}
                        <strong className="score-text ">{(row.original.AggregatedSimilarityScore * 100).toFixed(2) + '%'}</strong>
                    </span>
                </span>
            );
        },
        sortType: "alphanumeric",
    }
    
    //   {
    //     Header: "Similarity Score",
    //     accessor: (value) => value["AggregatedSimilarityScore"],
    //     Cell: ({ row }) => (
    //         <span>
    //            {(row.original.AggregatedSimilarityScore * 100).toFixed(2) + '%'}
    //         </span>
    //       ),
    //   }
    ],
    []
  );

  const orgColumns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        accessor: "OrgName",
        Cell: ({ row }) => (
          <a
            href={
              row.original.URL.startsWith("http")
                ? row.original.URL
                : `http://${row.original.URL}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.OrgName}
          </a>
        ),
        sortType: "alphanumeric",
      },
    ],
    []
  );

  // Prepare CSV data based on active tab
  const csvData = activeTab === "aiir_faculty" 
    ? facultyData.map(row => ({ Name: row.Name, URL: row.URL,  Email: row.Email, Department: row.Department, Designation: row.Designation, AggregatedSimilarityScore: row.AggregatedSimilarityScore }))
    : orgData.map(row => ({ OrganizationName: row.OrgName, URL: row.URL }));

  const csvHeaders = activeTab === "aiir_faculty" 
    ? [{ label: "Faculty Name", key: "Name" }, { label: "URL", key: "URL" }, { label: "Email", key: "Email" }, { label: "Department", key: "Department" },{ label: "Designation", key: "Designation" },{ label: "AggregatedSimilarityScore", key: "AggregatedSimilarityScore" }]
    : [{ label: "Organization Name", key: "OrganizationName" }, { label: "URL", key: "URL" }];

  return (
    <div className="air-container">
      <div className="main-container">
        <div className="search-header">
          <div className="search-title">
            <div className="background-image" />
            <h1 className="section-title">
              <span className="black-on-gold">
                Find Your Potential Match with AIIR
              </span>
            </h1>
            <p>
              Leverage our cutting edge Artificial-Intelligence Information
              Retrieval (AIIR) System, driven by Semantic Analysis, to
              effortlessly identify potential faculty and organizational matches
              closely aligned with your search text. Uncover the most relevant
              connections with precision and efficiency through our advanced
              technology.
            </p>
          </div>
        </div>
        <div className="search-box-container">
          <input
            type="text"
            placeholder="Enter your search text"
            className="search-box"
            value={searchTerm}
            onChange={handleSearch}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          {searchTerm && (
            <Tooltip title="Clear Search" placement="bottom-start">
              <span className="clear-icon" onClick={clearSearch}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Tooltip>
          )}
          <button
            type="submit"
            className="search-button"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </button>
        </div>

        <div className="tabs-container">
          <div className="tabs-left">
            <div
              className={`tab ${
                activeTab === "aiir_faculty" ? "active-tab" : ""
              } ${!availableTabs.includes("aiir_faculty") ? "disabled-tab" : ""}`}
              onClick={() => handleTabChange("aiir_faculty")}
            >
              Faculty
            </div>
            <div
              className={`tab ${
                activeTab === "aiir_organization" ? "active-tab" : ""
              } ${!availableTabs.includes("aiir_organization") ? "disabled-tab" : ""}`}
              onClick={() => handleTabChange("aiir_organization")}
            >
              Organization
            </div>
          </div>
          {(facultyData.length > 0 || orgData.length > 0) && (
            <div className="export-container">
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={`${activeTab}_data.csv`}
                className="export-link"
              >
                <Tooltip title="Export" placement="bottom-start">
                  <FontAwesomeIcon icon={faFileExport} className="export-icon" />
                </Tooltip>
              </CSVLink>
            </div>
          )}
        </div>

        <div className="table-container">
          {isLoading && <LoadingSpinner />}
          <div className="air-search-data">
            {activeTab === "aiir_faculty" && facultyData.length > 0 && (
              <TableWithAccordian_New columns={facultyColumns} data={facultyData} />
            )}
            {activeTab === "aiir_organization" && orgData.length > 0 && (
              <TableWithAccordian_New columns={orgColumns} data={orgData} />
            )}
            {isApiCalled && !isLoading &&
              searchTerm &&
              (facultyData.length === 0 && orgData.length === 0) && (
                <p className="no-data-text">No matches found</p>
              )}
            {((!isLoading && !searchTerm) || !isApiCalled) && (
              <p className="no-data-text">
                Search for topics in the search box above
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIIR_New;
