import React, { useState, useEffect, useCallback } from "react";
import Table from "../Utilities/Table/Table";
import { getData, postData } from "../Services/AccessAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar as solidStar,
  faEyeSlash,
  faEye,
  faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import sustainableGoalsImages from "./SustainableGoalsImages";
import "./SharedTopics.css";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { API_MODULES } from "../Services/Settings";
import { capitalizeFirstLetter } from "../Utilities/utils";

const SharedTopics = ({ searchTerm, updateSearchTerm }) => {
  const [updatedSearchTerm, setUpdatedSearchTerm] = useState(searchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [activeMainTab, setActiveMainTab] = useState("OrgToFaculty");
  const [activeTab, setActiveTab] = useState("details");
  const [sourceData, setSourceData] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [commonBigrams, setCommonBigrams] = useState([]);
  const [hiddenBigrams, sethiddenBigrams] = useState([]);
  const [favoriteBigrams, setFavoriteBigrams] = useState([]);
  const currentOrgName = React.useRef(null);
  const currentFacultyName = React.useRef(null);
  const [sharedTopicsTitle, setSharedTopicsTitle] = useState(
    "Shared topics among organizations and researchers"
  );
  const [viewButtonClickedIn, setViewButtonClickedIn] = useState("");
  const [sharedTopicsDataCsv, setSharedTopicsDataCsv] = useState([[]]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      setIsLoading(true);
      try {
        let endpointKey;
        let params = { searchKeyword: updatedSearchTerm };
        let geoData = localStorage.getItem("userLocation") || null;

        // Check if the search term is a single word
        if (
          updatedSearchTerm.trim() !== "" &&
          updatedSearchTerm.trim().split(" ").length === 1
        ) {
          toast.warning("Please enter at least two words for a more accurate search");
          setIsLoading(false);
          return;
        }

        if (activeMainTab === "OrgToFaculty") {
          endpointKey =
            updatedSearchTerm !== ""
              ? "getOrgToFacultySearchResults"
              : "getDefaultOrgToFaculty";
        } else if (activeMainTab === "OrgToOrg") {
          endpointKey =
            updatedSearchTerm !== ""
              ? "getOrgToOrgSearchResults"
              : "getDefaultOrgToOrg";
        } else {
          endpointKey =
            updatedSearchTerm !== ""
              ? "getFacultyToFacultySearchResults"
              : "getDefaultFacultyToFaculty";
        }

        if (geoData && updatedSearchTerm !== "") {
          params.geoData = geoData;
        }

        const data = await getData(API_MODULES.SEARCH, endpointKey, params);

        let searchResultsData = data.data;

        if (updatedSearchTerm === "") {
          if (activeMainTab === "OrgToOrg") {
            searchResultsData = data.data.defaultOrgToOrgList;
          } else if (activeMainTab === "FacultyToFaculty") {
            searchResultsData = data.data.facultyToFacultyList;
          }
        }

        setSearchResults(searchResultsData);
      } catch (error) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [updatedSearchTerm, activeMainTab]);

  useEffect(() => {
    setUpdatedSearchTerm(searchTerm);
  }, [searchTerm]);

  // To set Shared Topics CSV data
  useEffect(() => {
    if (sourceData && targetData) {
      const sharedTopicsCsv = [];
  
      // Helper function to ensure URLs start with http:// or https://
      const formatUrl = (url) => (url?.startsWith("http") ? url : `https://${url}`);
  
      // Functions to handle data pushing based on the active tab
      const pushOrgToFacultyData = () => {
        sharedTopicsCsv.push([
          capitalizeFirstLetter(sourceData.organizationName),
          capitalizeFirstLetter(targetData.name),
        ]);
        sharedTopicsCsv.push([
          formatUrl(sourceData.url),
          formatUrl(targetData.isearch),
        ]);
        sharedTopicsCsv.push([
          capitalizeFirstLetter(sourceData.address),
          capitalizeFirstLetter(targetData.department),
        ]);
      };
  
      const pushOrgToOrgData = () => {
        sharedTopicsCsv.push([
          capitalizeFirstLetter(sourceData.organizationName),
          capitalizeFirstLetter(targetData.organizationName),
        ]);
        sharedTopicsCsv.push([
          formatUrl(sourceData.url),
          formatUrl(targetData.url),
        ]);
        sharedTopicsCsv.push([
          capitalizeFirstLetter(sourceData.address),
          capitalizeFirstLetter(targetData.address),
        ]);
      };
  
      const pushFacultyToFacultyData = () => {
        sharedTopicsCsv.push([
          capitalizeFirstLetter(sourceData.name),
          capitalizeFirstLetter(targetData.name),
        ]);
        sharedTopicsCsv.push([
          formatUrl(sourceData.isearch),
          formatUrl(targetData.isearch),
        ]);
        sharedTopicsCsv.push([
          capitalizeFirstLetter(sourceData.department),
          capitalizeFirstLetter(targetData.department),
        ]);
      };
  
      // Execute the appropriate function based on the activeMainTab
      if (activeMainTab === "OrgToFaculty") pushOrgToFacultyData();
      else if (activeMainTab === "OrgToOrg") pushOrgToOrgData();
      else if (activeMainTab === "FacultyToFaculty") pushFacultyToFacultyData();
  
      // Add shared topics
      sharedTopicsCsv.push([""]);
      sharedTopicsCsv.push(["#", "Shared Topics"]);
      commonBigrams.forEach((item, index) => {
        sharedTopicsCsv.push([index + 1, capitalizeFirstLetter(item.bigrams)]);
      });
  
      setSharedTopicsDataCsv(sharedTopicsCsv);
    }
  }, [commonBigrams]);

  const handleViewButtonClick = async (orgName, facultyName) => {
    setIsLoading(true);
    currentOrgName.current = orgName;
    currentFacultyName.current = facultyName;

    const orgDetailsParams = { organizationName: orgName };
    const facultyDetailsParams = { facultyName: facultyName };
    const commonBigramsParams = { organizationName: orgName, facultyName: facultyName, edgeType: 'View' };

    try {
      const [
        orgData,
        facultyData,
        commonBigrams,
        hiddenBigrams,
        favoriteBigrams,
      ] = await Promise.all([
        getData(API_MODULES.SEARCH, 'getOrganizationDetails', orgDetailsParams),
        getData(API_MODULES.SEARCH, 'getFacultyDetails', facultyDetailsParams),
        getData(API_MODULES.SEARCH, 'getCommonBigrams', commonBigramsParams),
        getData(API_MODULES.SEARCH, 'getHiddenBigrams'),
        getData(API_MODULES.SEARCH, 'getFavouriteBigrams'),
      ]);

      setSourceData(orgData.data[0]);
      setTargetData(facultyData.data[0]);
      setCommonBigrams(commonBigrams.data);
      sethiddenBigrams(hiddenBigrams.data);
      setFavoriteBigrams(favoriteBigrams.data);

      setActiveTab("details");
      setShowData(true);
      setIsLoading(false);
      setViewButtonClickedIn(activeMainTab);
    } catch (error) {
      toast.error("Error fetching data");
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleViewButtonClickForSourceTargetColumns = async (source, target) => {
    currentOrgName.current = source;
    currentFacultyName.current = target;
    const isOrgToOrg = activeMainTab === "OrgToOrg";

    const sourceParams = isOrgToOrg
      ? { organizationName: source }
      : { facultyName: source };

    const targetParams = isOrgToOrg
      ? { organizationName: target }
      : { facultyName: target };

    const orgToOrgCommonBigramsParams = {
      sorgname: currentOrgName.current,
      torgname: currentFacultyName.current,
      edgeType: 'View'
    };

    const facultyToFacultyCommonBigramsParams = {
      sfacultyname: currentOrgName.current,
      tfacultyname: currentFacultyName.current,
      edgeType: 'View'
    };

    try {
      setIsLoading(true);

      const [
        sourceData,
        targetData,
        commonBigrams,
        hiddenBigrams,
        favoriteBigrams,
      ] = await Promise.all([
        getData(API_MODULES.SEARCH, isOrgToOrg ? 'getOrganizationDetails' : 'getFacultyDetails', sourceParams),
        getData(API_MODULES.SEARCH, isOrgToOrg ? 'getOrganizationDetails' : 'getFacultyDetails', targetParams),
        getData(API_MODULES.SEARCH, isOrgToOrg ? 'getCommonBigramsListForOrgToOrg' : 'getCommonBigramsListForFacultyToFaculty', isOrgToOrg ? orgToOrgCommonBigramsParams : facultyToFacultyCommonBigramsParams),
        getData(API_MODULES.SEARCH, 'getHiddenBigrams'),
        getData(API_MODULES.SEARCH, 'getFavouriteBigrams'),
      ]);

      setSourceData(sourceData.data[0]);
      setTargetData(targetData.data[0]);
      setCommonBigrams(commonBigrams.data);
      sethiddenBigrams(hiddenBigrams.data);
      setFavoriteBigrams(favoriteBigrams.data);
      setActiveTab("details");
      setShowData(true);
      setIsLoading(false);
      setViewButtonClickedIn(activeMainTab);
    } catch (error) {
      toast.error("Error fetching data");
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleHideBigram = async (bigram) => {
    try {
      const params = { bigram_in: bigram };

      let commonBigramsParams, commonBigramsEndpointKey;
      if (activeMainTab === "OrgToFaculty") {
        commonBigramsParams = {
          organizationName: currentOrgName.current,
          facultyName: currentFacultyName.current,
          edgeType: 'View'
        };
        commonBigramsEndpointKey = 'getCommonBigrams';
      } else if (activeMainTab === "OrgToOrg") {
        commonBigramsParams = {
          sorgname: currentOrgName.current,
          torgname: currentFacultyName.current,
          edgeType: 'View'
        };
        commonBigramsEndpointKey = 'getCommonBigramsListForOrgToOrg';
      } else if (activeMainTab === "FacultyToFaculty") {
        commonBigramsParams = {
          sfacultyname: currentOrgName.current,
          tfacultyname: currentFacultyName.current,
          edgeType: 'View'
        };
        commonBigramsEndpointKey = 'getCommonBigramsListForFacultyToFaculty';
      }

      const insertHideBigramResponse = await postData(API_MODULES.SEARCH, 'insertHiddenBigrams', params);
      const commonBigrams = await getData(API_MODULES.SEARCH, commonBigramsEndpointKey, commonBigramsParams);
      const hiddenBigrams = await getData(API_MODULES.SEARCH, 'getHiddenBigrams');

      if (!insertHideBigramResponse.ok) {
        throw new Error("Failed to hide bigram.");
      }

      setCommonBigrams(commonBigrams.data);
      sethiddenBigrams(hiddenBigrams.data);
    } catch (error) {
      toast.error("Error hiding bigram");
      console.error("Error hiding bigram:", error);
    }
  };

  const handleUnhideBigram = async (bigram) => {
    try {
      const params = { bigram_in: bigram };

      let commonBigramsParams, commonBigramsEndpointKey;
      if (activeMainTab === "OrgToFaculty") {
        commonBigramsParams = {
          organizationName: currentOrgName.current,
          facultyName: currentFacultyName.current,
          edgeType: 'View'
        };
        commonBigramsEndpointKey = 'getCommonBigrams';
      } else if (activeMainTab === "OrgToOrg") {
        commonBigramsParams = {
          sorgname: currentOrgName.current,
          torgname: currentFacultyName.current,
          edgeType: 'View'
        };
        commonBigramsEndpointKey = 'getCommonBigramsListForOrgToOrg';
      } else if (activeMainTab === "FacultyToFaculty") {
        commonBigramsParams = {
          sfacultyname: currentOrgName.current,
          tfacultyname: currentFacultyName.current,
          edgeType: 'View'
        };
        commonBigramsEndpointKey = 'getCommonBigramsListForFacultyToFaculty';
      }

      const unHideBigramResponse = await postData(API_MODULES.SEARCH, 'unhideBigrams', params);
      const commonBigrams = await getData(API_MODULES.SEARCH, commonBigramsEndpointKey, commonBigramsParams);
      const hiddenBigrams = await getData(API_MODULES.SEARCH, 'getHiddenBigrams');

      if (!unHideBigramResponse.ok) {
        throw new Error("Failed to unhide bigram.");
      }

      setCommonBigrams(commonBigrams.data);
      sethiddenBigrams(hiddenBigrams.data);
    } catch (error) {
      toast.error("Error unhiding bigram");
      console.error("Error unhiding bigram:", error);
    }
  };

  const handleGoalImageClick = (goal) => {
    // Actual code commented below
    // updateSearchTerm(goal); // To set the correct search text
    // setUpdatedSearchTerm(goal);

    // Temporary under development message
    toast.warn("This section is under development. Check back soon!");
  };

  const handleToggleFavoriteBigram = useCallback(
    async (bigram) => {
      const isFavorited = favoriteBigrams.some((item) => item.bigram === bigram);
      const endpointKey = isFavorited ? 'removeBigramFromFav' : 'insertFavouriteBigrams';
      const successMessage = isFavorited ? "Successfully removed from Favorites" : "Successfully added to Favorites";
      const errorMessage = isFavorited ? "Failed to remove from Favorites" : "Failed to add to Favorites";
      const params = { bigram_in: bigram };

      try {
        const toggleFavoritesResponse = await postData(API_MODULES.SEARCH, endpointKey, params);

        if (!toggleFavoritesResponse.ok) {
          throw new Error(errorMessage);
        }

        // Get Favorite Bigrams
        const favoriteBigramsResponse = await getData(API_MODULES.SEARCH, 'getFavouriteBigrams');
        setFavoriteBigrams(favoriteBigramsResponse.data);
        toast.success(successMessage);
      } catch (error) {
        console.error(errorMessage, error);
        toast.error(errorMessage);
      }
    },
    [favoriteBigrams, setFavoriteBigrams]
  );

  const isFavorite = useCallback(
    (bigram) => favoriteBigrams.some((item) => item.bigram === bigram),
    [favoriteBigrams]
  );

  const orgToFacultyColumns = React.useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "organizationName",
        Cell: ({ value }) => capitalizeFirstLetter(value),
        sortType: "alphanumeric",
      },
      {
        Header: "Faculty",
        accessor: "facultyName",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "University",
        accessor: "university",
        Cell: ({ value }) => {
          if (!value) return null;
          return <span className={`university-text ${value.toLowerCase()}`}>{value}</span>;
      },
        // Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          const renderExportLink = () => {
            if (
              currentOrgName.current === row.original.organizationName &&
              currentFacultyName.current === row.original.facultyName
            ) {
              return (
                <>
                  <CSVLink
                    data={sharedTopicsDataCsv}
                    filename={`Shared_Topics_${currentOrgName.current}_${currentFacultyName.current}.csv`}
                  >
                    <Tooltip
                      title="Export Shared Topics as CSV"
                      placement="bottom-end"
                    >
                      <span className="export-icon">
                        <FontAwesomeIcon icon={faFileCsv} />
                      </span>
                    </Tooltip>
                  </CSVLink>
                </>
              );
            }
            return null;
          };

          return (
            <>
              <a
                className="view-button"
                tabIndex="0"
                onClick={() =>
                  handleViewButtonClick(
                    row.original.organizationName,
                    row.original.facultyName
                  )
                }
              >
                View
              </a>
              {renderExportLink()}
            </>
          );
        },
      },
    ],
    [handleViewButtonClick]
  );

  const sourceTargetColumns = React.useMemo(() => {
    const columns = [
      {
        Header: activeMainTab === "OrgToOrg" ? "Source Organization" : "Source Faculty",
        accessor: "source",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
    ];
  
    if (activeMainTab !== "OrgToOrg") {
      columns.push(
        {
          Header: "Source Uni.",
          accessor: "sUniversity",
          Cell: ({ value }) => {
            if (!value) return null;
            return <span className={`university-text ${value.toLowerCase()}`}>{value}</span>;
        },
        }
      );
    }
  
    columns.push(
      {
        Header: activeMainTab === "OrgToOrg" ? "Target Organization" : "Target Faculty",
        accessor: "target",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      }
    );
  
    if (activeMainTab !== "OrgToOrg") {
      columns.push({
        Header: "Target Uni.",
        accessor: "tUniversity",
        Cell: ({ value }) => {
          if (!value) return null;
          return (
            <span className={`university-text ${value.toLowerCase()}`}>
              {value}
            </span>
          );
        },
      });
    }
  
    columns.push({
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const renderExportLink = () => {
          if (
            currentOrgName.current === row.original.source &&
            currentFacultyName.current === row.original.target
          ) {
            return (
              <CSVLink
                data={sharedTopicsDataCsv}
                filename={`Shared_Topics_${currentOrgName.current}_${currentFacultyName.current}.csv`}
              >
                <Tooltip title="Export Shared Topics as CSV" placement="bottom-end">
                  <span className="export-icon">
                    <FontAwesomeIcon icon={faFileCsv} />
                  </span>
                </Tooltip>
              </CSVLink>
            );
          }
          return null;
        };
  
        return (
          <>
            <a
              className="view-button"
              tabIndex="0"
              onClick={() =>
                handleViewButtonClickForSourceTargetColumns(
                  row.original.source,
                  row.original.target
                )
              }
            >
              View
            </a>
            {renderExportLink()}
          </>
        );
      },
    });
  
    return columns;
  }, [activeMainTab, handleViewButtonClickForSourceTargetColumns]);
  
  const sharedTopicsColumns =
    activeMainTab === "OrgToFaculty"
      ? orgToFacultyColumns
      : sourceTargetColumns;

  const detailsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Favorite",
        accessor: "favorite",
        Cell: ({ row }) => (
          <span
            onClick={() => handleToggleFavoriteBigram(row.original.bigrams)}
          >
            <Tooltip
              title={`${
                isFavorite(row.original.bigrams)
                  ? "Remove from Favorites"
                  : "Add to Favorites"
              }`}
              placement="bottom-start"
            >
              <FontAwesomeIcon
                icon={solidStar}
                className={`star-icon ${
                  isFavorite(row.original.bigrams) ? "gold" : ""
                }`}
              />
            </Tooltip>
          </span>
        ),
      },
      {
        Header: () => (
          <div className="custom-header">
            <span>Shared Topic</span>
            <span className="count-badge">{commonBigrams.length}</span>
          </div>
        ),
        accessor: "bigrams",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Hide",
        accessor: "hide",
        Cell: ({ row }) => (
          <span
            className={`hide-icon ${
              isFavorite(row.original.bigrams) ? "disabled" : ""
            }`}
            onClick={() => handleHideBigram(row.original.bigrams)}
          >
            <Tooltip
              title={`${
                isFavorite(row.original.bigrams)
                  ? "Cannot hide Favorite"
                  : "Hide topic"
              }`}
              placement="bottom-start"
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </Tooltip>
          </span>
        ),
      },
    ],
    [isFavorite, handleToggleFavoriteBigram, handleHideBigram, commonBigrams.length]
  );

  const hiddenBigramsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: () => (
          <div className="custom-header">
            <span>Shared Topic</span>
            <span className="count-badge">{hiddenBigrams.length}</span>
          </div>
        ),
        accessor: "bigram",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Hidden Date",
        accessor: "hiddenDate",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Unhide",
        accessor: "unhide",
        Cell: ({ row }) => (
          <span
            className="unhide-icon"
            onClick={() => handleUnhideBigram(row.original.bigram)}
          >
            <Tooltip title="Unhide topic" placement="bottom-start">
              <FontAwesomeIcon icon={faEye} className="unhide-icon" />
            </Tooltip>
          </span>
        ),
      },
    ],
    [handleUnhideBigram, hiddenBigrams.length]
  );

  const favoriteBigramsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: () => (
          <div className="custom-header">
            <span>Shared Topic</span>
            <span className="count-badge">{favoriteBigrams.length}</span>
          </div>
        ),
        accessor: "bigram",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Modified Date",
        accessor: "hiddenDate",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "",
        accessor: "unfavorite",
        Cell: ({ row }) => (
          <span
            onClick={() => handleToggleFavoriteBigram(row.original.bigram)}
          >
            <Tooltip
              title={`${
                isFavorite(row.original.bigram)
                  ? "Remove from Favorites"
                  : "Add to Favorites"
              }`}
              placement="bottom-start"
            >
              <FontAwesomeIcon
                icon={solidStar}
                className={`star-icon ${
                  isFavorite(row.original.bigram) ? "gold" : ""
                }`}
              />
            </Tooltip>
          </span>
        ),
      },
    ],
    [isFavorite, handleToggleFavoriteBigram, favoriteBigrams.length]
  );

  const handleMainTabClick = (tab) => {
    setActiveMainTab(tab);
    setSharedTopicsTitle(
      tab === "OrgToFaculty"
        ? "Shared topics among organizations and researchers"
        : tab === "OrgToOrg"
        ? "Shared topics among organizations"
        : "Shared topics among researchers"
    );
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const setOrgDetails = (orgData) => {
    return (
      <div className="details-org-data">
        <a
          href={
            orgData?.url?.startsWith("http")
              ? orgData?.url
              : `https://${orgData?.url}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="data-title"
        >
          {orgData?.organizationName ? capitalizeFirstLetter(orgData.organizationName) : ""}
        </a>
        <div className="data-footer">{capitalizeFirstLetter(orgData?.address)}</div>
      </div>
    );
  };

  const setFacultyDetails = (facultyData) => {
    return (
      <div className="details-faculty-data">
        <a
          href={
            facultyData?.isearch?.startsWith("http")
              ? facultyData?.isearch
              : `https://${facultyData?.isearch}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="data-title"
        >
          {`${facultyData?.name ? capitalizeFirstLetter(facultyData.name) : ""} ${
            facultyData?.designation
              ? `(${capitalizeFirstLetter(facultyData?.designation)})`
              : ""
          }`}
        </a>
        <div className="data-footer">{capitalizeFirstLetter(facultyData?.department)}</div>
      </div>
    );
  };

  return (
    <div className="shared-topics-container">
      <h2 className="container-title">
        {/* Shared topics among organizations and researchers */}
        {sharedTopicsTitle}
      </h2>
      <div className="shared-topics-main-tabs">
        <Tooltip
          title="Shared topics among organizations and researchers"
          placement="bottom-start"
        >
          <div
            className={`shared-topics-tab ${
              activeMainTab === "OrgToFaculty" ? "active" : ""
            }`}
            onClick={() => handleMainTabClick("OrgToFaculty")}
          >
            <div className="custom-header">
              <span>Org To Faculty</span>
              {activeMainTab === "OrgToFaculty" && !isLoading &&(
                <span className="count-badge-inverse">
                  {searchResults.length}
                </span>
              )}
            </div>
          </div>
        </Tooltip>
        <Tooltip
          title="Shared topics among organizations"
          placement="bottom-start"
        >
          <div
            className={`shared-topics-tab ${
              activeMainTab === "OrgToOrg" ? "active" : ""
            }`}
            onClick={() => handleMainTabClick("OrgToOrg")}
          >
            <div className="custom-header">
              <span>Org To Org</span>
              {activeMainTab === "OrgToOrg" && !isLoading && (
                <span className="count-badge-inverse">
                  {searchResults.length}
                </span>
              )}
            </div>
          </div>
        </Tooltip>
        <Tooltip
          title="Shared topics among researchers"
          placement="bottom-start"
        >
          <div
            className={`shared-topics-tab ${
              activeMainTab === "FacultyToFaculty" ? "active" : ""
            }`}
            onClick={() => handleMainTabClick("FacultyToFaculty")}
          >
            <div className="custom-header">
              <span>Faculty To Faculty</span>
              {activeMainTab === "FacultyToFaculty" && !isLoading && (
                <span className="count-badge-inverse">
                  {searchResults.length}
                </span>
              )}
            </div>
          </div>
        </Tooltip>
      </div>
      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div className="shared-topics-main-panel">
          {searchResults.length > 0 ? (
            <>
              <Table
                columns={sharedTopicsColumns}
                data={searchResults}
                disableFilterOptionsForIds={["action"]}
              />
            </>
          ) : (
            !isLoading && <p className="no-data-text">No matches found</p>
          )}
        </div>
        <div className="shared-topics-side-panel">
          <div className="shared-topics-tabs">
            <div
              className={`shared-topics-tab ${
                activeTab === "details" ? "active" : ""
              }`}
              onClick={() => handleTabClick("details")}
            >
              Details
            </div>
            <div
              className={`shared-topics-tab ${
                activeTab === "favorites" ? "active" : ""
              }`}
              onClick={() => handleTabClick("favorites")}
            >
              Favorites
            </div>
            <div
              className={`shared-topics-tab ${
                activeTab === "hidden-topics" ? "active" : ""
              }`}
              onClick={() => handleTabClick("hidden-topics")}
            >
              Hidden Topics
            </div>
          </div>
          <div className="side-panel-data-container">
            {showData ? (
              <div>
                {activeTab === "details" && (
                  <div className="details-data">
                    <div className="details-data-header">
                      {viewButtonClickedIn === "OrgToFaculty" && (
                        <>
                          {setOrgDetails(sourceData)}
                          {setFacultyDetails(targetData)}
                        </>
                      )}
                      {viewButtonClickedIn === "OrgToOrg" && (
                        <>
                          {setOrgDetails(sourceData)}
                          {setOrgDetails(targetData)}
                        </>
                      )}
                      {viewButtonClickedIn === "FacultyToFaculty" && (
                        <>
                          {setFacultyDetails(sourceData)}
                          {setFacultyDetails(targetData)}
                        </>
                      )}
                    </div>
                    <div className="details-data-table">
                      <Table
                        columns={detailsColumns}
                        data={commonBigrams}
                        // showFilterOptions={false}
                        disableFilterOptionsForIds={[
                          "s.no",
                          "favorite",
                          "hide",
                        ]}
                        tableDataHeight="269px"
                      />
                    </div>
                  </div>
                )}

                {activeTab === "hidden-topics" && (
                  <div className="hidden-topics-table">
                    <Table
                      columns={hiddenBigramsColumns}
                      data={hiddenBigrams}
                      //showFilterOptions={false}
                      disableFilterOptionsForIds={["s.no", "unhide"]}
                      tableDataHeight="359px"
                    />
                  </div>
                )}

                {activeTab === "favorites" && (
                  <div className="favorites-table">
                    <Table
                      columns={favoriteBigramsColumns}
                      data={favoriteBigrams}
                      //showFilterOptions={false}
                      disableFilterOptionsForIds={["s.no", "unfavorite"]}
                      tableDataHeight="359px"
                    />
                  </div>
                )}
              </div>
            ) : (
              <p className="no-data-text">
                {isLoading ? "" : "Click on view button for more details"}
              </p>
            )}
          </div>
        </div>
      </div>
      <h2 className="container-title goals-title">
        Sustainable Development Goals
      </h2>
      <div className="goals-container">
        {sustainableGoalsImages.map((item, index) => (
          <div key={index}>
            <img
              src={item.image}
              alt={item.title}
              className="goal-image"
              onClick={() => handleGoalImageClick(item.title)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedTopics;
