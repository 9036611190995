import API_ENDPOINTS from '../../config/apiEndpoints';
import { Modules } from '../../config/constants';

// export const BASE_URL = process.env.REACT_APP_CORE_API_BASE_URL || "http://localhost:5006/";
// export const FAST_API_BASE_URL = process.env.REACT_APP_FAST_API_BASE_URL || "http://localhost:8001/";
// export const AIR_API_BASE_URL = process.env.REACT_APP_AIR_API_BASE_URL || "http://localhost:6568/";
export const BASE_URL = window.baseURL || "http://localhost:5006/";
export const FAST_API_BASE_URL = window.fastApiBaseURL || "http://localhost:8001/";
export const AIR_API_BASE_URL = window.airApiBaseURL || "http://localhost:6568/";
export const NEW_AIIR_API_BASE_URL = window.aiirApiBaseURL || "http://localhost:6568/";
export const API_MODULES = Modules;
export { API_ENDPOINTS };