import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faFileAlt,
  faTrophy,
  faBook,
  faRocket,
  faMicroscope,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "./ProjectTimeline.css"; // CSS styling file

const ProjectTimeline = () => {
  const timelineRef = useRef(null);

  const scrollLeft = () => {
    timelineRef.current.scrollBy({
      left: -350,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    timelineRef.current.scrollBy({
      left: 350,
      behavior: "smooth",
    });
  };

  return (
    <div className="timeline-container">
      <div className="timeline-scroll-arrow left" onClick={scrollLeft}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <div className="timeline" ref={timelineRef}>
        <div className="timeline-item">
          <div className="timeline-date">Jan, 2020</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faCog} />
          </div>
          <div className="timeline-content">
            <p>Planning Started</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">May, 2020</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
          <div className="timeline-content">
            <p>Patent Filed</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">July, 2020</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faTrophy} />
          </div>
          <div className="timeline-content">
            <p>Demo 1</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">Dec, 2020</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faBook} />
          </div>
          <div className="timeline-content">
            <p>KAT V.1 Published</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">Mar, 2021</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faRocket} />
          </div>
          <div className="timeline-content">
            <p>Demo 2 &amp; NDA</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">Aug, 2021</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faMicroscope} />
          </div>
          <div className="timeline-content">
            <p>KAT Testing</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">Dec, 2021</div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faBook} />
          </div>
          <div className="timeline-content">
            <p>KAT V.2 Published</p>
          </div>
        </div>
      </div>
      <div className="timeline-scroll-arrow right" onClick={scrollRight}>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </div>
  );
};

export default ProjectTimeline;
