import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgb(211,211,211,0.4)",
          position: "absolute",
        }}
        open={true}
      >
        <CircularProgress style={{ color: "#8c1d40" }} />
      </Backdrop>
    </div>
  );
};

export default LoadingSpinner;
