import jwt_decode from "jsonwebtoken";

export const decodeJWT = (token) => {
  try {
    return jwt_decode.decode(token);
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export function replacePlaceholders(template, params) {
  return template.replace(/{(\w+)}/g, (_, key) =>
    encodeURIComponent(params[key] || "")
  );
}

export function replacePlaceholdersForAiir(template, params) {
  return template.replace(/{(\w+)}/g, (_, key) => {
    let value = params[key] || "";
    
    // Sanitize only if the key is 'group_name'
    if (key === "group_name") {
      value = value.replace(/[^a-zA-Z]/g, "");
    }
    
    // Encode the value using encodeURIComponent
    return encodeURIComponent(value);
  });
}

export function capitalizeFirstLetter(text) {
  if (text == null) {
    return "";
  }

  return text
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}
