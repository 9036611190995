import { useEffect } from "react";
import SessionManager from "./SessionManager";

const Logout = () => {
  useEffect(() => {
    SessionManager.removeUserSession();
    window.location.href = "/login";
  }, []);

  return null;
};

export default Logout;
