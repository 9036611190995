import React, { useState, useEffect, useRef } from "react";
import { getData } from "../Services/AccessAPI";
import Table from "../Utilities/Table/Table";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import "./Keywords.css";
import { Tooltip } from "@mui/material";
import { API_MODULES } from "../Services/Settings";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../Utilities/utils";
import Switch from "@mui/material/Switch";

const Keywords = ({ searchTerm }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [facultyData, setFacultyData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [viewData, setViewData] = useState({
    facultyBigramList: [],
    orgBigramList: [],
  });
  const [facultyDataCsv, setFacultyDataCsv] = useState([['Faculty Name', 'ASU Search URL', 'Topic Count']]);
  const [orgDataCsv, setOrgDataCsv] = useState([['Organization Name', 'Org URL', 'Topic Count']]);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [semanticMatchEnabled, setSemanticMatchEnabled] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          searchText: searchTerm,
          geoData: localStorage.getItem("userLocation") || ''
        };

        const data = await getData(API_MODULES.SEARCH, 'getKeywordSearchResults', params);
        setSearchResults(data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      }
    };

    if (searchTerm !== "") {
      setIsLoading(true);
      fetchData();
    }
  }, [searchTerm]);

  useEffect(() => {
    setFacultyData(viewData.facultyBigramList);
    setOrgData(viewData.orgBigramList);

    let facultyCsv = [['Faculty Name', 'ASU Search URL', 'Topic Count']];
    let orgCsv = [['Organization Name', 'Org URL', 'Topic Count']];

    viewData.facultyBigramList.forEach((item) => {
      facultyCsv.push([capitalizeFirstLetter(item.name), item.url, item.weight])
    });
    viewData.orgBigramList.forEach((item) => {
      orgCsv.push([capitalizeFirstLetter(item.name), item.url, item.weight])
    });

    setFacultyDataCsv(facultyCsv);
    setOrgDataCsv(orgCsv);

  }, [viewData]);

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const handleViewButtonClick = async (searchWord) => {
    setIsLoading(true);
    try {
      const params = { searchText: searchWord };
      const data = await getData(API_MODULES.SEARCH, 'getOrgFacultyListForKeyword', params);
      setViewData(data.data);
      setIsViewClicked(true);
      setSelectedKeyword(searchWord);
      setShowExportOptions(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    setShowExportOptions(!showExportOptions);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };

  const handleToggleSwitch = (event) => {
    setSemanticMatchEnabled(event.target.checked);
  }

  // Define table columns and data
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <span>Search Results for Topic</span>
            <span className="count-badge">{searchResults.length}</span>
          </div>
        ),
        accessor: "bigrams",
        sortType: "alphanumeric",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          const renderExportLink = () => {
            if (selectedKeyword === row.original.bigrams) {
              return (
                <>
                  <div className="dropdown" ref={dropdownRef}>
                    <Tooltip title="Export CSV" placement="bottom-start">
                      <span
                        className={`dropdown-toggle ${
                          showExportOptions ? "active" : ""
                        }`}
                        onClick={toggleDropdown}
                      >
                        <FontAwesomeIcon icon={faFileCsv} />
                      </span>
                    </Tooltip>
                    {showExportOptions && (
                      <ul className="dropdown-menu" style={{ left: "auto" }}>
                        <CSVLink
                          data={facultyDataCsv}
                          filename={`${selectedKeyword}_faculty_matches.csv`}
                        >
                          <li onClick={toggleDropdown}>Export Faculty data</li>
                        </CSVLink>

                        <CSVLink
                          data={orgDataCsv}
                          filename={`${selectedKeyword}_org_matches.csv`}
                        >
                          <li onClick={toggleDropdown}>Export Org data</li>
                        </CSVLink>
                      </ul>
                    )}
                  </div>
                </>
              );
            }
            return null;
          };
      
          return (
            <>
              <a
                className="view-button"
                tabIndex="0"
                onClick={() => handleViewButtonClick(row.original.bigrams)}
              >
                View
              </a>
              {renderExportLink()}
            </>
          );
        },
      }
      
    ],
    [searchResults.length, selectedKeyword, showExportOptions]
  );

  // Define table columns and data for faculty
  const facultyColumns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <span>Faculty Name</span>
            <span className="count-badge">{facultyData.length}</span>
          </div>
        ),
        accessor: "name",
        Cell: ({ row }) => (
          <a
            href={row.original.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {capitalizeFirstLetter(row.original.name)}
          </a>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: "University",
        accessor: "university",
        Cell: ({ value }) => <span className={`university-text ${value.toLowerCase()}`}>{value}</span>,
      },
      // {
      //   Header: "Topic Count",
      //   accessor: "weight",
      //   sortType: (rowA, rowB, columnId) => {
      //     const valueA = parseFloat(rowA.values[columnId]);
      //     const valueB = parseFloat(rowB.values[columnId]);
      //     return valueA - valueB;
      //   },
      // },
    ],
    [facultyData.length]
  );

  // Define table columns and data for organizations
  const orgColumns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <span>Organization Name</span>
            <span className="count-badge">{orgData.length}</span>
          </div>
        ),
        accessor: "name",
        Cell: ({ row }) => (
          <a
            href={
              row.original.url?.startsWith("http")
                ? row.original.url
                : `http://${row.original.url}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {capitalizeFirstLetter(row.original.name)}
          </a>
        ),
        sortType: "alphanumeric",
      },
      // {
      //   Header: "Topic Count",
      //   accessor: "weight",
      //   sortType: (rowA, rowB, columnId) => {
      //     const valueA = parseFloat(rowA.values[columnId]);
      //     const valueB = parseFloat(rowB.values[columnId]);
      //     return valueA - valueB;
      //   },
      // },
    ],
    [orgData.length]
  );

  return (
    <div className="keywords-container">
      <h2 className="container-title">
        {selectedKeyword ? (
          <span>
            Showing faculty and organization matches for the keyword:{" "}
            <span className="black-on-gold">{selectedKeyword}</span>
          </span>
        ) : (
          "Keyword Lookup"
        )}
        {/* <span className="toggle-switch">
          <span className={!semanticMatchEnabled ? "selected" : ""}>
            Exact Match
          </span>
          <Switch
            checked={semanticMatchEnabled}
            onChange={handleToggleSwitch}
            size="small"
          />
          <span className={semanticMatchEnabled ? "selected" : ""}>
            Semantic Match
          </span>
        </span> */}
      </h2>

      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div
          className={`keyword-search-data ${
            searchResults.length > 0 ? "" : "full-width"
          }`}
        >
          {searchResults.length > 0 ? (
            <>
              <Table
                columns={columns}
                data={searchResults}
                disableFilterOptionsForIds={["action"]}
              />
            </>
          ) : (
            !isLoading && (
              <p className="no-data-text">
                Search for topics in the search box above
              </p>
            )
          )}
        </div>
        {searchResults.length > 0 && (
          <div className="keyword-view-data">
            {!isViewClicked ? (
              <p className="no-data-text">
                Click on the view button to get faculty and organization matches
              </p>
            ) : (
              <div className="keyword-side-panel">
                <div className="keyword-faculty-data">
                  {viewData.facultyBigramList.length > 0 ? (
                    <Table columns={facultyColumns} data={facultyData} />
                  ) : (
                    <p className="no-data-text">No faculty matches found</p>
                  )}
                </div>
                <div className="keyword-org-data">
                  {viewData.orgBigramList.length > 0 ? (
                    <Table columns={orgColumns} data={orgData} />
                  ) : (
                    <p className="no-data-text">
                      No organization matches found
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Keywords;
