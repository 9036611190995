import React, { useState, useEffect, useMemo } from "react";
import Table from "../Utilities/Table/Table";
import { getData, postData } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  Select,
} from "@mui/material";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { API_MODULES } from "../Services/Settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
// import "./Affiliations.css";

const Affiliations = ({ affiliations, setAffiliations }) => {
  const [groups, setGroups] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [selectedAffiliationId, setSelectedAffiliationId] = useState(null);
  const [selectedAffiliationName, setSelectedAffiliationName] = useState(null);
  const [groupFeatureMap, setGroupFeatureMap] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editedAffiliation, setEditedAffiliation] = useState({
    affiliationId: "",
    affiliationName: "",
    subscriptions: [],
    selectedSubscriptionId: null,
    selectedSubscriptionName: null,
    expirationDate: "",
    isActive: false,
    show: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [groupsResponse, featuresResponse] =
          await Promise.all([
            // getData(API_MODULES.ADMIN, "getAllAffiliations"),
            getData(API_MODULES.ADMIN, "activeOrganisations"),
            getData(API_MODULES.ADMIN, "getAllFeatures"),
          ]);

        // setAffiliations(affiliationsResponse);
        setGroups(groupsResponse);
        setFeatures(featuresResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setAffiliations]);

  const handleViewButtonClick = async (affiliate_name, affiliate_id) => {
    setIsViewClicked(true);
    setSelectedAffiliationId(affiliate_id);
    setSelectedAffiliationName(affiliate_name);

    try {
      const response = await getData(
        API_MODULES.ADMIN,
        "getAffiliationGroupFeatureMapping",
        {
          affiliation_id: affiliate_id,
        }
      );

      if (
        response &&
        response.length > 0 &&
        response[0].affiliate_groups_feature
      ) {
        const groupFeatureMapping = JSON.parse(
          response[0].affiliate_groups_feature
        );
        setGroupFeatureMap(groupFeatureMapping);
        setCheckedGroups(Object.keys(groupFeatureMapping));
        setSelectedGroup(null);
        setCheckedFeatures([]);
      } else {
        resetGroupFeatureState();
      }
    } catch (error) {
      console.error("Error fetching group-feature mapping:", error);
      toast.error("Error fetching group-feature mapping");
      resetGroupFeatureState();
    }
  };

  const resetGroupFeatureState = () => {
    setGroupFeatureMap({});
    setSelectedGroup(null);
    setCheckedGroups([]);
    setCheckedFeatures([]);
  };

  const handleGroupViewButtonClick = (groupName) => {
    setSelectedGroup(groupName);
    setCheckedFeatures(groupFeatureMap[groupName] || []);
  };

  const handleManageAffiliation = async (row) => {
    try {
      let params = {affiliation_id: row.affiliate_id};
      const response = await getData(API_MODULES.ADMIN, 'getSubscriptionForAffiliation', params);

      setEditedAffiliation({
        affiliationId: row.affiliate_id,
        affiliationName: row.affiliate_name,
        subscriptions: response.data?.subscriptionDetailList,
        selectedSubscriptionId: response.data?.subscriptionDetailList?.find(sub => sub.is_selected)?.id || "",
        selectedSubscriptionName: response.data?.subscriptionDetailList?.find(sub => sub.is_selected)?.name || "",
        expirationDate: response.data?.affiliationDetail?.expiration_date.split("T")[0],
        isActive: response.data?.affiliationDetail?.is_active,
        show: true,
      });
    }
    catch(error) {
      console.error("Error fetching subscriptions:", error);
      toast.error("Error fetching subscriptions");
    }
  };

  const handleSave = async () => {
    try {
      let payload = {
        affiliation_id: editedAffiliation.affiliationId,
        subscription_id: editedAffiliation.selectedSubscriptionId,
        expiration_date: `${editedAffiliation.expirationDate}T00:00:00`,
        is_approved: editedAffiliation.isActive,
      };
  
      const response = await postData(API_MODULES.ADMIN, "updateSubscriptionPlan", {}, payload);
  
      if (!response.ok) {
        throw new Error("Failed to update subscription plan.");
      }
  
      toast.success("Subscription plan updated!");
      handleClose();
    } catch (error) {
      toast.error("Failed to update subscription plan!");
      console.error("Error updating subscription plan:", error);
    }
  };
  
  
  const handleClose = () => {
    setEditedAffiliation((prevEditedAffiliation) => ({
      ...prevEditedAffiliation,
      show: false,
    }));
  };

  const handleGroupCheckboxChange = (groupName) => {
    setCheckedGroups((prevCheckedGroups) => {
      const newCheckedGroups = prevCheckedGroups.includes(groupName)
        ? prevCheckedGroups.filter((group) => group !== groupName)
        : [...prevCheckedGroups, groupName];

      setGroupFeatureMap((prevGroupFeatureMap) => {
        const newGroupFeatureMap = { ...prevGroupFeatureMap };
        if (newCheckedGroups.includes(groupName)) {
          newGroupFeatureMap[groupName] = newGroupFeatureMap[groupName] || [];
        } else {
          delete newGroupFeatureMap[groupName];
        }
        return newGroupFeatureMap;
      });

      return newCheckedGroups;
    });
  };

  // const handleGroupCheckboxChange = (groupName) => {
  //   setCheckedGroups((prevCheckedGroups) => {
  //     let newCheckedGroups;
  
  //     if (selectedAffiliationName === "demo") {
  //       // For "demo", only allow one selection (radio button behavior)
  //       newCheckedGroups = [groupName];
  //     } else {
  //       // For other affiliations, allow multiple selections (checkbox behavior)
  //       newCheckedGroups = prevCheckedGroups.includes(groupName)
  //         ? prevCheckedGroups.filter((group) => group !== groupName)
  //         : [...prevCheckedGroups, groupName];
  //     }
  
  //     setGroupFeatureMap((prevGroupFeatureMap) => {
  //       const newGroupFeatureMap = { ...prevGroupFeatureMap };
  //       if (newCheckedGroups.includes(groupName)) {
  //         newGroupFeatureMap[groupName] = newGroupFeatureMap[groupName] || [];
  //       } else if (selectedAffiliationName !== "demo") {
  //         // In "demo" mode, do not delete other groups' data
  //         delete newGroupFeatureMap[groupName];
  //       }
  //       console.log("newGroupFeatureMap: ", newGroupFeatureMap);
  //       return newGroupFeatureMap;
  //     });
  
  //     return newCheckedGroups;
  //   });
  // };
  

  const handleFeatureCheckboxChange = (featureName) => {
    if (selectedGroup) {
      setCheckedFeatures((prevCheckedFeatures) => {
        const newCheckedFeatures = prevCheckedFeatures.includes(featureName)
          ? prevCheckedFeatures.filter((name) => name !== featureName)
          : [...prevCheckedFeatures, featureName];

        setGroupFeatureMap((prevGroupFeatureMap) => {
          const newGroupFeatureMap = { ...prevGroupFeatureMap };
          if (newCheckedFeatures.includes(featureName)) {
            if (!newGroupFeatureMap[selectedGroup]) {
              newGroupFeatureMap[selectedGroup] = [];
            }
            newGroupFeatureMap[selectedGroup] = [
              ...new Set([...newGroupFeatureMap[selectedGroup], featureName]),
            ];
          } else {
            newGroupFeatureMap[selectedGroup] = newGroupFeatureMap[selectedGroup].filter(
              (name) => name !== featureName
            );
          }
          return newGroupFeatureMap;
        });

        return newCheckedFeatures;
      });
    } else {
      toast.warn("Please select a group first");
    }
  };

  const handleUpdateButtonClick = async () => {
    setIsUpdating(true);
    const payload = {
      affiliation_id: selectedAffiliationId,
      group_ids: groups
        .filter((group) => checkedGroups.includes(group.group_name))
        .map((group) => group.group_id),
      feature_names: checkedFeatures,
      subscription_name: "gold",
    };

    try {
      const response = await postData(
        API_MODULES.ADMIN,
        "attachPoliciesToAffiliation",
        {},
        payload
      );

      const data = await response.json();

      if (data.success) {
        toast.success("Policies updated successfully");
      } else {
        toast.error("Failed to update policies");
      }
    } catch (error) {
      console.error("Error updating policies:", error);
      toast.error("Error updating policies");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleClearChanges = async () => {
    if (selectedAffiliationId) {
      await handleViewButtonClick(null, selectedAffiliationId);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Affiliation",
        accessor: "affiliate_name",
        sortType: "alphanumeric",
        Cell: ({ row }) => (
          <div
            className={`row-item ${
              row.original.affiliate_id === selectedAffiliationId
                ? "selected-item"
                : ""
            }`}
            onClick={() =>
              handleViewButtonClick(
                row.original.affiliate_name,
                row.original.affiliate_id
              )
            }
          >
            {row.original.affiliate_name}
            {/* <FontAwesomeIcon
              icon={row.original.affiliate_id === selectedAffiliationId ? faAnglesRight : faAngleRight}
              className={`angle-icon ${row.original.affiliate_id === selectedAffiliationId ? 'selected' : ''}`}
              style={{
                color: row.original.affiliate_id === selectedAffiliationId ? "#333" : "#999",
              }}
            /> */}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "manage",
        Cell: ({ row }) => (
          <div>
            <a
              className="view-button"
              tabIndex="0"
              onClick={() => handleManageAffiliation(row.original)}
            >
              Manage
            </a>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row }) => (
          <div>
            <a
              className="view-button"
              tabIndex="0"
              onClick={() =>
                handleViewButtonClick(
                  row.original.affiliate_name,
                  row.original.affiliate_id
                )
              }
            >
              View
            </a>
          </div>
        ),
      },
    ],
    [selectedAffiliationId]
  );

  const groupColumns = useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <Tooltip title="Select All" placement="bottom-start">
              <input
                type="checkbox"
                checked={checkedGroups.length === groups.length}
                onChange={() => {
                  const allGroupNames = groups.map((group) => group.group_name);
                  const newCheckedGroups = checkedGroups.length === groups.length ? [] : allGroupNames;
  
                  setCheckedGroups(newCheckedGroups);
  
                  setGroupFeatureMap((prevGroupFeatureMap) => {
                    const newGroupFeatureMap = { ...prevGroupFeatureMap };
                    if (newCheckedGroups.length > 0) {
                      newCheckedGroups.forEach((groupName) => {
                        newGroupFeatureMap[groupName] = newGroupFeatureMap[groupName] || [];
                      });
                    } else {
                      allGroupNames.forEach((groupName) => {
                        delete newGroupFeatureMap[groupName];
                      });
                    }
                    return newGroupFeatureMap;
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
        accessor: "checkbox",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={checkedGroups.includes(row.original.group_name)}
            onChange={() => handleGroupCheckboxChange(row.original.group_name)}
          />
        ),
      },
      {
        Header: "Group",
        accessor: "group_name",
        sortType: "alphanumeric",
        Cell: ({ row }) => (
          <div
            className={`row-item ${
              row.original.group_name === selectedGroup ? "selected-item" : ""
            }`}
            onClick={() => handleGroupViewButtonClick(row.original.group_name)}
          >
            {row.original.group_name}
            <FontAwesomeIcon
              icon={row.original.group_name === selectedGroup ? faAnglesRight : faAngleRight}
              className={`angle-icon ${row.original.group_name === selectedGroup ? 'selected' : ''}`}
              style={{
                color: row.original.group_name === selectedGroup ? "#333" : "#999",
              }}
            />
          </div>
        ),
      },
    ],
    [selectedGroup, groups, checkedGroups]
  );

  // const groupColumns = useMemo(
  //   () => [
  //     {
  //       Header: () => (
  //         selectedAffiliationName !== "demo" && (
  //           <div className="custom-header">
  //             <Tooltip title="Select All" placement="bottom-start">
  //               <input
  //                 type="checkbox"
  //                 checked={checkedGroups.length === groups.length}
  //                 onChange={() =>
  //                   setCheckedGroups(
  //                     checkedGroups.length === groups.length
  //                       ? []
  //                       : groups.map((group) => group.group_name)
  //                   )
  //                 }
  //               />
  //             </Tooltip>
  //           </div>
  //         )
  //       ),
  //       accessor: "checkbox",
  //       Cell: ({ row }) => (
  //         <input
  //           type={selectedAffiliationName === "demo" ? "radio" : "checkbox"}
  //           name={selectedAffiliationName === "demo" ? "groupSelection" : undefined}  // To group the radio buttons
  //           checked={checkedGroups.includes(row.original.group_name)}
  //           onChange={() => handleGroupCheckboxChange(row.original.group_name)}
  //         />
  //       ),
  //     },
  //     {
  //       Header: "Group",
  //       accessor: "group_name",
  //       sortType: "alphanumeric",
  //       Cell: ({ row }) => (
  //         <div
  //           className={`row-item ${
  //             row.original.group_name === selectedGroup ? "selected-item" : ""
  //           }`}
  //           onClick={() => handleGroupViewButtonClick(row.original.group_name)}
  //         >
  //           {row.original.group_name}
  //           <FontAwesomeIcon
  //             icon={row.original.group_name === selectedGroup ? faAnglesRight : faAngleRight}
  //             className={`angle-icon ${row.original.group_name === selectedGroup ? 'selected' : ''}`}
  //             style={{
  //               color: row.original.group_name === selectedGroup ? "#333" : "#999",
  //             }}
  //           />
  //         </div>
  //       ),
  //     },
  //   ],
  //   [selectedGroup, groups, checkedGroups, selectedAffiliationName]
  // );
  

  const featureColumns = useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <Tooltip title="Select All" placement="bottom-start">
              <input
                type="checkbox"
                checked={checkedFeatures.length === features.length}
                onChange={() => {
                  const allFeatureNames = features.map((feature) => feature.feature_name);
                  const newCheckedFeatures = checkedFeatures.length === features.length ? [] : allFeatureNames;
  
                  setCheckedFeatures(newCheckedFeatures);
  
                  setGroupFeatureMap((prevGroupFeatureMap) => {
                    const newGroupFeatureMap = { ...prevGroupFeatureMap };
                    if (selectedGroup) {
                      if (newCheckedFeatures.length > 0) {
                        newGroupFeatureMap[selectedGroup] = newCheckedFeatures;
                      } else {
                        newGroupFeatureMap[selectedGroup] = [];
                      }
                    }
                    return newGroupFeatureMap;
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
        accessor: "checkbox",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={checkedFeatures.includes(row.original.feature_name)}
            onChange={() =>
              handleFeatureCheckboxChange(row.original.feature_name)
            }
          />
        ),
      },
      {
        Header: "Feature",
        accessor: "feature_name",
        sortType: "alphanumeric",
      },
    ],
    [checkedFeatures, features]
  );

  return (
    <>
      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div
          className={`entity-data ${
            affiliations.length > 0 ? "" : "full-width"
          }`}
        >
          {affiliations.length > 0 ? (
            <Table
              columns={columns}
              data={affiliations}
              disableFilterOptionsForIds={["action", "manage"]}
            />
          ) : (
            !isLoading && <p className="no-data-text">No affiliations found</p>
          )}
        </div>
        {affiliations.length > 0 && (
          <div className="entity-view-data">
            {isViewClicked && (
              <>
                <div className="entity-side-panel">
                  <div className="groups-data">
                    {groups.length > 0 ? (
                      <Table
                        columns={groupColumns}
                        data={groups}
                        disableFilterOptionsForIds={["checkbox"]}
                        tableDataHeight={"400px"}
                        showPagination={false}
                      />
                    ) : (
                      <p className="no-data-text">No groups found</p>
                    )}
                  </div>
                  <div className="features-data">
                    {selectedGroup && features.length > 0 ? (
                      <Table
                        columns={featureColumns}
                        data={features}
                        disableFilterOptionsForIds={["checkbox"]}
                        tableDataHeight={"400px"}
                        showPagination={false}
                      />
                    ) : (
                      <p className="no-data-text">Select a group</p>
                    )}
                  </div>
                </div>
                <div className="options-container">
                  <button
                    type="button"
                    className="option-button clear"
                    onClick={handleClearChanges}
                  >
                    Clear Changes
                  </button>
                  <button
                    type="button"
                    className="option-button update"
                    onClick={handleUpdateButtonClick}
                    disabled={isUpdating}
                  >
                    {isUpdating && (
                      <CircularProgress
                        size={20}
                        style={{ marginRight: "8px", color: "white" }}
                      />
                    )}
                    Update
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Dialog open={editedAffiliation.show} onClose={handleClose} className="dialog">
        <DialogTitle>Manage Affiliation - {editedAffiliation.affiliationName}</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="manage-title"
            style={{ marginBottom: "20px" }}
          >
            Manage subscription status for{" "}
            <strong>{editedAffiliation.affiliationName}</strong>
          </DialogContentText>
          <FormControl
            variant="outlined"
            className="subscription-select"
            sx={{ m: 1, minWidth: 100 }}
            size="small"
          >
            <InputLabel>Subscription</InputLabel>
            <Select
              value={editedAffiliation.selectedSubscriptionName}
              onChange={(e) => {
                const selectedSubscription =
                  editedAffiliation.subscriptions.find(
                    (sub) => sub.name === e.target.value
                  );
                setEditedAffiliation((prev) => ({
                  ...prev,
                  selectedSubscriptionName: selectedSubscription.name,
                  selectedSubscriptionId: selectedSubscription.id,
                }));
              }}
              label="Subscription"
            >
              {editedAffiliation.subscriptions.map((subscription) => (
                <MenuItem key={subscription.id} value={subscription.name}>
                  {subscription.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={editedAffiliation.isActive}
                onChange={(e) =>
                  setEditedAffiliation((prev) => ({
                    ...prev,
                    isActive: e.target.checked,
                  }))
                }
                color="primary"
              />
            }
            label="Active"
          />
          <TextField
            margin="dense"
            id="expirationDate"
            label="Expiration Date"
            type="date"
            className="date-select"
            value={editedAffiliation.expirationDate || ""}
            onChange={(e) =>
              setEditedAffiliation((prev) => ({
                ...prev,
                expirationDate: e.target.value, // Keep as YYYY-MM-DD
              }))
            }
            // fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{ marginTop: 2, minWidth: 100, m: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="Mui-button">
            Cancel
          </Button>
          <Button onClick={handleSave} className="Mui-button save">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Affiliations;
