import React, { useState, useEffect } from "react";
import { getData } from "../Services/AccessAPI";
import WordCloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { API_MODULES } from "../Services/Settings";

const WordCloudComponent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData(API_MODULES.DASHBOARD, 'getSearchHistoryKeyword')
      .then((responseData) => setData(responseData.data))
      .catch((error) => console.error("Error:", error));
  }, []);

  // Transform the data into the required format for the word cloud component
  const wordCloudData = data.map((item) => ({
    text: item.searchKeyword,
    value: item.searchCount,
  }));

  // Define options for the word cloud component
  const options = {
    colors: [
      "#6b6ecf",
      "#ad494a",
      "#8ca252",
      "#de9ed6",
      "#ffc627",
      "#e7ba52",
      "#9c9ede",
      "#ce6dbd",
      "#9e3556",
      "#fdb515",
      "#393b79",
    ], // Maroon and Gold colors
    fontFamily: "impact",
    rotations: 2,
    rotationAngles: [0, -90], // Set fixed rotation angle
    scale: "sqrt", // Use square root scaling for better size distribution
    spiral: "archimedean", // Use Archimedean spiral layout
    padding: 3, // Increase padding between words
    fontSizes: [20, 80], // Adjust the range of font sizes
  };

  return (
    <div style={{ height: "280px", width: "100%", padding: "0 20px" }}>
      <WordCloud words={wordCloudData} options={options} />
    </div>
  );
};

export default WordCloudComponent;
